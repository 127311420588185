@import '@thinkific/toga/styles/_variables';

.footer {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  button {
    margin-left: auto;
  }
}
