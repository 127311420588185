@import '@thinkific/toga/styles/_variables';

$single-column-width: 640px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: $padding-3;
  padding-bottom: $padding-2;

  &__single-column {
    max-width: $single-column-width;
    padding-top: $padding-3;
    padding-bottom: $padding-2;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 0;
    padding-bottom: $padding-3;

    &__single-column {
      padding-top: 0;
      padding-bottom: $padding-3;
    }
  }
}

.container--no-bg {
  background: none;

  &::before {
    display: none;
  }
}

.title__container {
  display: flex;
  align-items: center;
  margin-top: 8px;

  &--with-action {
    justify-content: space-between;
  }
}

.title {
  position: relative;
  color: $color-white;
  word-break: break-word;

  &__no-bg {
    padding: 0 $margin-3;
    color: $color-gray-7;
  }
}

.spacer {
  height: 32px;
}
