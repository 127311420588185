@import '@thinkific/toga/styles/_variables';

.container {
  display: block;
  height: 172px;
  margin-bottom: $margin-3;
  border: 1px solid transparent;
  color: $color-gray-7;

  &:hover,
  &:focus {
    border-color: $color-indigo-5;
    text-decoration: none;
    box-shadow: 0 8px 12px 0 $color-black-20-opacity;

    .arrow-icon {
      visibility: visible;
    }
  }
}

.surface {
  padding: $padding-3 $padding-3 $padding-4;
}

.info__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.arrow-icon {
  font-size: 32px;
  visibility: hidden;
}

.name {
  margin-top: $margin-3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
