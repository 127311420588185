//sass-lint:disable no-important

@import '@thinkific/toga/styles/toga-react';
@import '@thinkific/toga/styles/core/_base';
@import '@thinkific/toga/styles/core/_layout';
@import '@thinkific/toga/styles/core/_sidebar';

.ReactModal__Body--open {
  //sass-lint:disable-line class-name-format
  .overlay {
    background-color: $color-black-50-opacity;
    z-index: 2704;
  }
}

// Ensure the FileStack picker errors are visible to users
// Open issue here: https://github.com/filestack/filestack-js/issues/335
.fsp-picker-holder {
  .fsp-notifications__container {
    position: fixed;
  }
}

.awsappsync {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-white;
  z-index: 9999;

  &.awsappsync--rehydrating {
    transition: opacity 0.5s linear;
    opacity: 1;
    visibility: visible;
  }

  &.awsappsync--rehydrated {
    transition: visibility 0s 0.5s, opacity 0.5s linear;
    opacity: 0;
    visibility: hidden;
  }

  .spinner {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
  }
}

fieldset {
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.button {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.table-container .table-container__footer {
  //sass-lint:disable-line force-element-nesting
  display: none;
}

.inline-actions__separator {
  display: none;
}

.action-button {
  border-color: $color-gray-3 !important;
  background: $color-white !important;
  color: $color-indigo-9 !important;
}
