@import '@thinkific/toga/styles/_variables';

.button {
  margin-left: $margin-2;
}

p {
  margin-bottom: $margin-2;
}

footer {
  text-align: right;
}

.spinner {
  margin-left: $margin-base;
}
