@import '~@thinkific/toga/styles/variables';
@import '~@thinkific/toga/styles/mixins/button';

.container {
  display: flex;
}

.infobox {
  margin-top: 1rem;
  padding: 0 1rem;
  background-color: $color-gray-2;

  &__upload {
    button {
      @include button--secondary;
    }
  }
}

div.infobox__alert {
  max-width: 100%;
  margin: 1rem auto 0;
  border-radius: 4px;
}

.sections {
  &__title {
    padding: 1rem;
    padding-bottom: 0;
  }
}

.snippets {
  &__title {
    padding: 1rem;
    padding-bottom: 0;
  }

  &__list {
    margin: 0;
    padding: 1rem;
    list-style-type: none;
  }

  &__listitem {
    margin: 0.8rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
