@import '@thinkific/toga/styles/_variables';

.toggle {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
}

.submenu {
  opacity: 0;
  animation: enterMenu 1s forwards cubic-bezier(0.075, 0.82, 0.165, 1);

  a {
    font-weight: 400;
  }
}

.active {
  &::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -5px;
    width: 7px;
    height: 7px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: $color-yellow-5;
    content: '';
  }
}

@keyframes enterMenu {
  100% {
    opacity: 1;
  }
}
