@import '@thinkific/toga/styles/_variables';

.image {
  border-radius: 4px;
}

.header {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-2;
  }

  &__title {
    padding-top: 4px;
  }

  &__actions {
    display: flex;

    // We usually don't use * but in this case
    // we want to make sure that spacing is right
    > * {
      flex-shrink: 0;
      margin-left: $margin-2;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.container {
  margin-bottom: $margin-3;
  padding: $padding-3 $padding-3 $padding-4;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.description {
  margin-bottom: $margin-4;
}

.description--without-title {
  margin-top: $margin-6;
}

.description__link {
  margin: $margin-2;
}

.hasFooter {
  margin-bottom: 0;
}

.primary {
  color: $color-white;
  background-color: $color-indigo-5;
}

.collapsible {
  .header__container {
    cursor: pointer;
    margin-bottom: 0;
  }
  .content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.3s all ease;
  }
}

.isCollapsed {
  .header__container {
    cursor: pointer;
    margin-bottom: 16px;
  }
  .content {
    height: unset;
    opacity: 1;
    transition: 0.3s all ease;
  }
}

.icon {
  font-size: 2rem;
}

[data-card-icon] {
  padding: 7px 0 0 8px;
}

[data-icon-content='true'] {
  h3 {
    margin-top: 0;
  }
}
