@import '~@thinkific/toga/styles/variables';

.container {
  display: flex;
}

.input__wrapper {
  display: grid;
  align-items: end;
  width: 100%;
  grid-template-columns: auto fit-content(50%);
  grid-gap: 1rem;

  div {
    margin-bottom: 0;
  }
}

div.install__alert {
  max-width: 100%;
  margin: unset;
  margin: 1rem auto;
  border-radius: 4px;
}
