@import '@thinkific/toga/styles/_variables';

.alert {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-indigo-5;
  width: 100%;
  height: 64px;
  padding: 8px;
  box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);
  z-index: 2704;
}

.message {
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-white;
}

.verify-button {
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-gray-3;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .alert {
    height: 54px;
  }
}
