@import '~@thinkific/toga/styles/variables';

.title {
  padding: 0 0 40px 15px;

  h1 {
    margin-left: 24px;
  }

  img {
    width: 48px;
  }
}

.container {
  display: flex;
}

.input__wrapper {
  flex: 1 1 auto;
  padding-bottom: $margin-2;

  input {
    &:read-only {
      background-color: $color-gray-2;
    }
  }
}

.input__label {
  display: flex;
  align-items: center;

  svg {
    fill: $color-gray-5;
  }
}

.access__btn {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
  padding-left: 16px;

  button {
    width: 100%;
  }
}

.imageInput__wrapper {
  max-width: 33%;
  margin: 8px 0;
}

.footer {
  text-align: right;
}
