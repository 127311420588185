@import '~@thinkific/toga/styles/variables';

.selectScopes {
  margin-top: $margin-4;
}

.availableScopes {
  margin-top: $margin-2;
}

.scopeContainer {
  border-bottom: 1px solid $color-gray-3;
  justify-content: space-between;
  margin-top: $margin-2;
}

.lastScopeContainer {
  justify-content: space-between;
  margin-top: $margin-2;
  margin-bottom: -1rem;
}

.scopeInfo {
  max-width: 74%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-bottom: $margin-2;
}

.scopeDocLink {
  margin-top: 0.5rem;

  svg {
    filter: invert(45%) sepia(12%) saturate(2512%) hue-rotate(193deg) brightness(89%) contrast(96%);
  }
}

.selectScope {
  width: 26%;

  .dropdown__list-item-header {
    color: red;
  }

  li[class^="dropdown__list-item-header"] {
    color: #6f7282;
    font-size: .875rem;
    line-height: 1rem;
    padding: 8px 0 8px 24px;
    text-transform: capitalize;
  }
}

.modal__container {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  width: 560px;
  max-width: calc(100% - 24px);
  max-height: 90vh !important; // sass-lint:disable-line no-important
  transform: translate3d(-50%, -50%, 0);
  border-radius: $border-radius-base;
  outline: none;
  background-color: $color-white;
  box-shadow: $box-shadow-raised;
  overflow-y: auto;
}
.modalHeader {
  display: block;
  border-bottom-color: rgb(202, 204, 214);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-2 $padding-3;

    &.has-title {
      border-bottom: 1px solid $color-gray-3;
    }

    h3 {
      margin: 0;
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding: $padding-base * 1.5 $padding-2;
    }
  }

  &__close-button {
    margin-right: -8px;
    padding: 8px;
    border: 0;
    color: $color-gray-7;
    font-size: 1.5rem;
  }
}

.modalBody {
  flex-grow: 1;
  padding: $padding-3 $padding-3 $padding-3;
  background: $color-gray-1;
  font-size: 1rem;
  overflow-y: auto;

  &.no-padding {
    padding: 0;
  }

  &.no-title {
    padding: $padding-3;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: $padding-3 $padding-2;
  }
  label {
    display: flex;
    align-items: center;
    svg {
      fill: #757575;
    }
  }
}

.modalInput {
  width: 42%;
  margin-top: 0.4rem;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $padding-2 $padding-3;
  border-top: 1px solid $color-gray-3;
  text-align: right;

  button {
    &:not(:first-child) {
      margin-left: $margin-base;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: $padding-base * 1.5 $padding-2;
  }
}

.overlayModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-black-50-opacity !important;
  z-index: 2703; // This is the same z-index set on the sidebar, with lower values the sidebar will show up above the overlay
}

.scopes--anchor {
  text-decoration: underline;
}