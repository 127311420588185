@import '@thinkific/toga/styles/_variables';

.container {
  h5 {
    margin-bottom: $margin-2;
  }

  ul {
    padding-left: $margin-2;
    list-style: none;

    li {
      &:last-child {
        margin-bottom: $margin-3;
      }
    }
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
