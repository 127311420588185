@import '@thinkific/toga/styles/_variables';

.link-button {
  padding-left: 8px;
  font-size: 16px;
}

.footer {
  align-items: center;
  display: flex;
  padding: $margin-2 0;

  button {
    &:nth-of-type(1) {
      margin-left: auto;
    }
  }
}

.is-invalid {
  // Needed due to known bug with cssnano. When css is minified it converts
  // center right calc(0.375em + 0.1875rem) to 100% calc(0.375em + 0.1875rem)
  // https://github.com/facebook/create-react-app/issues/7265
  background-position: right calc(0.375em + 0.1875rem) center !important;
}
