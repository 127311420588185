@import '@thinkific/toga/styles/_variables';

.banner {
  color: $color-gray-9;
  width: 100%;
  height: 160px;
  padding: $padding-2;
  position: fixed;
  z-index: 99;

  @media screen and (min-width: $breakpoint-sm) {
    height: 80px;
  }

  .banner-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    max-width: 1200px;
    margin: auto;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      text-align: left;
    }
  }

  .banner-message {
    max-width: 900px;
    margin-bottom: $margin-2;

    @media screen and (min-width: $breakpoint-sm) {
      margin-bottom: 0;
      margin-right: $margin-2;
    }
  }
}

.warning {
  background-color: #e6aa67;
}

.info {
  background-color: $color-indigo-5;
  color: white;
}
