@import '@thinkific/toga/styles/_variables';

.enter {
  animation: enterActions 1s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
}

.footer {
  text-align: right;

  button {
    &:nth-of-type(2) {
      margin-left: $margin-2;
    }
  }
}

.loader {
  margin-left: $margin-2;
}

@keyframes enterActions {
  100% {
    opacity: 1;
  }
}
