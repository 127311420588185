@import '@thinkific/toga/styles/_variables'; //sass-lint:disable-line clean-import-paths

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0 0 $padding-base;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 1;

  i {
    margin-right: $margin-base;
    font-size: 24px;
  }

  &:hover,
  &:focus {
    color: $color-gray-3;
    text-decoration: none;
  }

  &__no-bg {
    color: $color-gray-7;
  }
}
