@import '@thinkific/toga/styles/_variables';

.publishedAt {
  margin-bottom: $margin-3;
}

.image {
  max-width: calc(100% + 48px);
  margin-left: -$margin-3;
  margin-right: -$margin-3;
}

.article {
  margin-top: $margin-3;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote {
    margin-bottom: 1rem;
  }
}
