@import '@thinkific/toga/styles/_variables';

.container {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  width: 560px;
  max-width: calc(100% - 24px);
  max-height: 90vh !important; // sass-lint:disable-line no-important
  transform: translate3d(-50%, -50%, 0);
  border-radius: $border-radius-base;
  outline: none;
  background-color: $color-white;
  box-shadow: $box-shadow-raised;
  overflow-y: auto;
}

.header {
  display: block;
  border-bottom: 1px solid $color-gray-3;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-2 $padding-3;

    &.has-title {
      border-bottom: 1px solid $color-gray-3;
    }

    h3 {
      margin: 0;
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding: $padding-base * 1.5 $padding-2;
    }
  }

  .close-button {
    margin-right: -8px;
    padding: 8px;
    border: 0;
    color: $color-gray-7;
    font-size: 1.5rem;
  }
}

.body {
  flex-grow: 1;
  padding: $padding-3 $padding-3 $padding-4;
  background: $color-gray-1;
  font-size: 1rem;
  overflow-y: auto;

  &.no-padding {
    padding: 0;
  }

  &.no-title {
    padding: $padding-3;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: $padding-3 $padding-2;
  }

  p {
    margin-bottom: $margin-3;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $padding-2 $padding-3;
  border-top: 1px solid $color-gray-3;
  text-align: right;

  button {
    &:not(:first-child) {
      margin-left: $margin-base;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: $padding-base * 1.5 $padding-2;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-black-50-opacity;
  z-index: 2703; // This is the same z-index set on the sidebar, with lower values the sidebar will show up above the overlay
}
