.icon__container {
  border-radius: 4px;
  overflow: hidden;
}

.app-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
