.tip-container {
  display: flex;
  align-items: center;
  margin: 0 0 8px 4px;

  span {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
  }
}

.custom-tooltip {
  left: -7px !important;
  z-index: 2704;
}

.custom-tooltip-inner {
  max-width: 240px !important;
}