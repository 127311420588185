@import '@thinkific/toga/styles/_variables';

$single-column-width: 640px;
$banner-desktop-height: 80px;
$banner-mobile-height: 160px;

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -55px;
  left: 0;
  height: 190px;
  background-color: $color-indigo-5;
  background-image: url('https://cdn.thinkific.com/68/20191017/2244c75db03933972e25c5cbd21ab1ab.png');
  background-repeat: no-repeat;
  background-size: 100%;
  content: '';

  &--none {
    background: none;
  }
}

.content__container--single-column {
  width: 640px;
}

.content {
  &__container {
    position: relative;
    margin-bottom: 40px;
  }
}

.is-active {
  display: block;
  background-color: $color-black-20-opacity;
  z-index: 2701;
}

div :global(.main) {
  margin: 0;

  @media (min-width: 1265px) {
    margin: 0 0 0 308px;
  }
}

.conditional-portal-wrapper {
  padding-top: $banner-mobile-height;

  .bg {
    top: $banner-mobile-height;
  }

  aside[class*='sidebar'] {
    top: $banner-mobile-height;
    height: calc(100% - #{$banner-mobile-height});
  }

  [class*='mobile-title-bar'][class*='mobile-title-bar--shadowed'] {
    top: $banner-mobile-height;
  }

  @media screen and (min-width: $breakpoint-sm) {
    padding-top: $banner-desktop-height;

    .bg {
      top: $banner-desktop-height;
    }

    aside[class*='sidebar'] {
      top: $banner-desktop-height;
      height: calc(100% - #{$banner-desktop-height});
    }

    [class*='mobile-title-bar'][class*='mobile-title-bar--shadowed'] {
      top: $banner-desktop-height;
    }
  }
}

.pushed-down {
  top: 64px;
}

.banner__btn:link {
  text-decoration: none;
  cursor: pointer;
}

.banner__anchor-text {
  color: white;
  text-decoration: underline;
}

.banner__anchor-text:hover {
  color: $color-indigo-9;
  text-decoration: underline;
}