@import '~@thinkific/toga/styles/variables';

$box-shadow-color: rgba(0, 0, 0, 0.15);

.container {
  display: block;
  height: 172px;
  margin-bottom: $margin-3;
  border: 1px solid transparent;
  color: $color-gray-7;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: $color-indigo-5;
    box-shadow: 0 8px 12px 0 $color-black-20-opacity;
  }
}

.surface {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: $padding-3 $padding-3 $padding-4;
}

.icon {
  display: block;
  width: 48px;
  height: 48px;
  font-size: 48px;
}

.icon,
.label {
  color: $color-indigo-5;
}
