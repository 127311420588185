@import '@thinkific/toga/styles/_variables';

.container {
  padding: $padding-4;
  background-color: $color-indigo-1;
}

.description {
  margin-bottom: $margin-2;
  margin-left: 0;
}

.icon {
  font-size: 32px;
  color: #12131a;
}
