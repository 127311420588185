@import '@thinkific/toga/styles/_variables';

.Welcome {
  &:after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #57608c
      url(https://cdn.thinkific.com/68/20191017/2244c75db03933972e25c5cbd21ab1ab.png)
      no-repeat center center fixed;
    background-size: cover;
    z-index: -1;
  }

  .Welcome__Container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: $breakpoint-sm) {
      max-width: 560px;
    }
  }

  .Welcome__Logo {
    $height: 20px;

    align-items: center;
    color: #fff;
    display: flex;
    font-weight: lighter;
    height: $height;
    margin-bottom: $margin-3;
    margin-top: 56px;

    .divider {
      background: #fff;
      height: $height;
      width: 1px;
      transform: rotate(15deg);
      margin: 0 $margin-2;
    }

    img {
      max-width: 120px;
    }

    span {
      letter-spacing: 2px;
    }
  }

  .Welcome__ThinkificLogo {
    max-width: 120px;
  }

  .Welcome__Header {
    width: 100%;
    margin-bottom: $margin-4;
    color: $color-white;
  }

  .Welcome__Surface {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 24px 24px 32px;
    width: 100%;

    .form-row:last-child {
      margin-bottom: 0;
    }
  }
}
