@import '@thinkific/toga/styles/_variables';

.container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $color-black-50-opacity;
  opacity: 0;
  z-index: 9999;
  animation: enterModal 0.3s forwards;
}

.content {
  display: block;
  width: 90%;
  height: 75%;
  transform: scale(0);
  transform-origin: 50% 50%;
  border: 2px solid $color-white;
  background: $color-white;
  animation: enterContent 0.3s forwards cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}

.close {
  display: inline-block;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: scale(1.3);
  border: 0;
  border-radius: 50%;
  background: $color-white;
  line-height: 30px;
  opacity: 0;
  z-index: 3;
  animation: enterClose 1s forwards 0.6s;

  i {
    color: $color-indigo-5;
  }
}

@keyframes enterModal {
  100% {
    opacity: 1;
  }
}

@keyframes enterContent {
  100% {
    transform: scale(1);
  }
}

@keyframes enterClose {
  20% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
