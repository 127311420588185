@import '@thinkific/toga/styles/_variables';

.publishedAt {
  margin-bottom: $margin-3;
}

.image {
  max-width: calc(100% + 48px);
  margin-left: -$margin-3;
  margin-right: -$margin-3;
}
