/*sass-lint:disable no-important*/

@import '@thinkific/toga/styles/_variables';

.sidebar {
  z-index: 2703 !important;
}

.container {
  height: 100%;
  transition: height 0.5s;

  &--shrunk {
    margin-top: 50px;
  }
}

.is-active {
  color: $color-yellow-5 !important;

  &:hover,
  &:focus {
    color: $color-yellow-5 !important;
    cursor: default;
  }
}

.is-hidden {
  display: none;
}

.show {
  display: block;
}

.is-opened {
  top: 0;
  left: 0 !important;
  transform: translateX(0);
}

.footer-list-item {
  &:hover {
    color: $color-gray-3 !important;
  }
}

.thinkific-logo {
  width: 212px;
  height: 16px;
}
